import React from "react"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import { Indicator, Title } from "src/templates"
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

const Stats = ({title, items}) => {
 const theme = useTheme();
 const md = useMediaQuery(theme.breakpoints.down('md'))
   
 const styles = {
  containerBackground: {
   padding: "90px 0px 0px",
   background: theme.palette.secondary.light+'20',
   textAlign: "center"
  },
  gridContainer: {
   position: 'relative',
   bottom: 0,
   width: '100%',
   padding: md ? "0px 0px 120px" : "80px 0px",
   margin: "0 auto",
   columnGap: 2,
   
  }
 }
 
 return (
  <Box sx={styles.containerBackground}>
   <Container>
    <Title text={title} />
    <Grid container rowSpacing={md ? 8 : 0} justifyContent="space-evenly" sx={ styles.gridContainer }>
     {items?.map((stat, index) => (
      <Grid item xs={6} md={2}>
       <Indicator size="45px" icon={stat?.icon} stat={stat?.title} label={stat?.description} variant="2" />
      </Grid>
     ))}
    </Grid>
   </Container>
  </Box>
 )}

export { Stats }
