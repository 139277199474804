import React, { Fragment } from "react"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Title } from "src/templates"

const WhatIsImpacto = ({ items }) => {
 const theme = useTheme();
 const lg = useMediaQuery(theme.breakpoints.down('lg'))

 const styles = {
  block: {
   padding: "90px 0px",
   background: theme.palette.secondary.light+'20'
  },
  blockContainer: {
   display: 'flex',
   alignItems: lg ? 'center' : 'flex-start',
   justifyContent: 'center',
   flexDirection: !lg ? 'row' : 'column',
   rowGap: 4,
   maxWidth: "1200px !important",
  },
  halfBlock: {
   width: lg ? "100%" : "50%",
   textAlign: "center",
  },
  separator: {
   display: "inline-block",
   margin: lg ? "16px 0px" : "0px 32px",
   width: lg ? "80px" : "1px",
   height: lg ? "1px" : "450px",
   background: lg ? "none" : `${theme.palette.primary.main}`,
  },
 }

 return (
  <Box sx={styles.block}>
   <Container sx={styles.blockContainer}>
    {items.map((item, index) => (
     <>
      <Box sx={styles.halfBlock}>
       <Title text={item?.title} arrow />
       <Box component="img" src={item?.image?.file?.url} mt={6} mb={6} height={index === 0 ? "250px" : "auto"} sx={{ maxHeight: "500px", maxWidth: "375px" }} width={index === 1 ? (lg ? "325px" : "375px" ) : "auto"} />
       <Typography>{item?.description}</Typography>
      </Box>
      {index === 0 && <Box sx={styles.separator}/>}
     </>
    ))}
   </Container>
  </Box>
 )
}

export { WhatIsImpacto }