import React from "react"
import { HeroSmall } from "src/templates"
import { WhatIsImpacto } from "src/components/common/WhatIsImpacto"
import { Testimonials } from "src/components/common/Testimonials"
import { ReportArea } from "../components/common/ReportArea";
import { Commitments } from "../components/common/Commitments";
import { Stats } from "src/components/common/Stats"

// Imports para los contenidos
import { getContent } from 'src/queries';

export default function Impacto() {

 const heroImpactoBannerResults = getContent("hero-impacto-banner", process.env.GATSBY_REACT_APP_REGION)
 const whatIsImpactoFeatures = getContent("what-is-impacto-features", process.env.GATSBY_REACT_APP_REGION)
 const testimonialsCarrouselResults = getContent("testimonials-carrousel-impacto", process.env.GATSBY_REACT_APP_REGION)
 const statsResults = getContent("stats-impacto", process.env.GATSBY_REACT_APP_REGION)
 const commitmentsResults = getContent("impacto-commitments-features", process.env.GATSBY_REACT_APP_REGION)
 const reportContentAreaResults = getContent("report-content-area", process.env.GATSBY_REACT_APP_REGION)
 return (
  <>
   {/* Bloque de video o imagen principal */}
   <HeroSmall
    title={heroImpactoBannerResults?.title}
    body={heroImpactoBannerResults?.body?.internal?.content}
    callToAction={heroImpactoBannerResults?.callToAction}
    backgroundDesktop={heroImpactoBannerResults?.backgroundDesktop?.file?.url}
    backgroundMobile={heroImpactoBannerResults?.backgroundMobile?.file?.url}
    variant="2"
   />
   <WhatIsImpacto
    items={whatIsImpactoFeatures?.features}/>
   {/* Bloque de testimoniales */}
   <Testimonials
    title={testimonialsCarrouselResults?.title}
    items={testimonialsCarrouselResults?.testimonials}
    backgroundImage={testimonialsCarrouselResults?.backgroundImage?.file?.url}
    variant="2"
   />
   {/* Estadísticas */}
   <Stats
    title={statsResults?.title}
    items={statsResults?.features}
   />
   {/* Bloque de Nuestros compromisos */}
   <Commitments
    title={commitmentsResults?.title}
    items={commitmentsResults?.features}
   />
   {/* Bloque de reportde de impacto */}
   <ReportArea
    title={reportContentAreaResults?.title}
    description={reportContentAreaResults?.body?.internal?.content}
    image={reportContentAreaResults?.images[0]?.file?.url}
    file={reportContentAreaResults?.images[1]?.file?.url}
    callToAction={reportContentAreaResults?.callToAction}
   />
  </>
 )
}
