import React from "react"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import { Title } from "src/templates"
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const Commitments = ({title, items}) => {
 const theme = useTheme();
 const md = useMediaQuery(theme.breakpoints.down('md'))
 const lg = useMediaQuery(theme.breakpoints.down('lg'))
   
 const styles = {
  containerBackground: {
   padding: "80px 0px 80px",
   maxWidth: "1050px",
   margin: "0 auto",
  },
  empresaBContainer: {
   margin: "0 auto",
   borderRadius: "20px",
   background: theme.palette.secondary.main,
   height: md ? "579px" : "343px",
   marginTop: 6,
   marginBottom: 12,
   display: "flex",
   flexDirection: md ? "column" : "row",
   columnGap: 4,
  },
  empresaBGreenContainer: {
   width: md ? "100%" : "340px",
   height: md ? "228px" : "100%",
   background: theme.palette.primary.main,
   borderRadius: md ? "20px 20px 100% 100%" : "20px 100% 100% 20px",
   display: "flex",
   justifyContent: "center",
   alignItems: "center"
  },
  empresaBPurpleContainer: {
   display: "flex",
   flexDirection: "column",
   maxWidth: 500,
   justifyContent: "center",
   alignItems: "center",
   margin: "0 auto",
   textAlign: "left",
   padding: md ? "60px 40px" : "auto"
  },
  containerBigCommitment: {
   display: "flex",
   flexDirection: md ? "column" : "row",
   marginBottom: 12,
   columnGap: 4,
   rowGap: 4,
  },
  containerSmallCommitment: {
   display: "flex",
   flexDirection: md ? "column" : "row",
   columnGap: 4,
   rowGap: 12,
  },
  halfBlock: {
   width: lg ? "100%" : "50%",
   display: "flex",
   flexDirection: "column",
   justifyContent: "flex-start",
   alignItems: "flex-start",
   rowGap: 4,
  },
 }
 
 return (
  <Box sx={styles.containerBackground}>
   <Container>
    <Box sx={{ textAlign: "center"}}>
     <Title text={title} />
    </Box>
    {/* Bloque de Empresa B */}
    <Box sx={styles.empresaBContainer}>
     <Box sx={styles.empresaBGreenContainer}>
      <Box component="img" src={items[0]?.image?.file?.url} sx={{ marginLeft: "-15px", height: md ? "115px" : "auto" }} />
     </Box>
     <Box sx={styles.empresaBPurpleContainer}>
      <Title text={items[0]?.title} arrow contrast />
      <Typography pt={4} align= {md ? "center" : "left"} color={theme.palette.text.secondary}>{items[0]?.description}</Typography>
     </Box>
    </Box>
    {/* Bloque de 3 objetivos de desarrollo */}
    <Box sx={styles.containerBigCommitment}>
     <Box sx={styles.halfBlock}>
      <Box sx={{margin: md ? "0 auto" : "inherit"}}>
       <Title text={items[1]?.title} arrow />
      </Box>
     </Box>
     <Box sx={styles.halfBlock}>
      <Box component="img" src={items[1]?.image?.file?.url} width="100%" />
     </Box>
    </Box>
    {/* Bloque de 2 objetivos específicos */}
    <Box sx={styles.containerSmallCommitment}>
     <Box sx={styles.halfBlock}>
      <Box sx={{margin: md ? "0 auto" : "inherit"}}>
       <Title text={items[2]?.title} arrow />
      </Box>
      <Box sx={{ maxWidth: !md ? "425px" : "100%", margin: md ? "0 auto" : "auto"}}>
       <Typography>{items[2]?.description}</Typography>
      </Box>
      <Box component="img" src={items[2]?.image?.file?.url} height="75px" sx={{ margin: "0 auto", width: "100%", maxWidth: md ? "300px" : "300px"}} />
     </Box>
     <Box sx={styles.halfBlock}>
      <Box sx={{margin: md ? "0 auto" : "inherit"}}>
       <Title text={items[3]?.title} arrow />
      </Box>
      <Box sx={{ maxWidth: !md ? "425px" : "100%", margin: md ? "0 auto" : "auto"}}>
       <Typography>{items[3]?.description}</Typography>
      </Box>
      <Box component="img" src={items[3]?.image?.file?.url} sx={{ margin: "0 auto", width: "100%", maxWidth: "120px"}} />
     </Box>
    </Box>
   </Container>
  </Box>
 )}

export { Commitments }
