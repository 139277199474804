
import React from "react"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Button, Title } from "src/templates"

const ReportArea = ({title, description, image, file, callToAction}) => {
    
 const theme = useTheme();
 const lg = useMediaQuery(theme.breakpoints.down('lg'))

 const NewlineText = (text) => {
  return text.split('\n').map((str, index) => <span key={index}>{str}<br/></span>);
 }
   
 const styles = {
  videoContentAreaBackground: {
   padding: "120px 0px",
   background: theme.palette.secondary.light+'20'
  },
  titleContainer: {
   display: 'flex',
   justifyContent: lg && "center",
   textAlign: lg ? "center" : "left"
  },
  title: {
   display: 'inline-block',
  },
  videoContentAreaContainer: {
   display: 'flex',
   alignItems: 'center',
   flexDirection: !lg ? 'row' : 'column',
   rowGap: 4,
   columnGap: 8,
   maxWidth: "1200px !important",
  },
  videoContentAreaGraphic: {
   width: !lg ? '50%' : '100%',
   display: 'flex',
   flexDirection: 'row',
   justifyContent: 'center',
   columnGap: 2,
   paddingTop: !lg ? '90px' : '0px',
  },
  image: {
   width: "100%",
   maxWidth: lg ? "375px" : "480px",
   minWidth: "172px",
  },
  videoContentAreaTexts: {
   width: !lg ? '100%' : '100%',
   textAlign: !lg ? 'right' : 'center',
   display: "table",
   position: "relative",
  },
  description: {
   padding: '35px 0px',
  },
  playpause: {
   display: "block",
   width: lg ? "100%" : "555px",
   height: "100%",
   position: "absolute",
   right: "0%",
   top: "0%",
   bottom: "0%",
   margin: "auto",
   backgroundSize: "contain",
   backgroundPosition: "center",
   opacity: "1",
   transition: "opacity 400ms",
   cursor: "pointer",
   "&[hidden]": {
    opacity: "0",
    display: "block",
   }
  },
  playIconContainer: {
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
   width: "100%",
   height: "100%",
  },
  playIconWrapper: {
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
   width: "64px",
   height: "64px",
   borderRadius: "100%",
   background: "#00474fc2",
  }
 }
 
 return (
  <Box sx={styles.videoContentAreaBackground}>
   <Container sx={styles.videoContentAreaContainer}>
    {/* Left side */}
    <Box component="img" src={image} sx={{...styles.image}} />
    {/* Right side */}
    <Box>
     <Box sx={styles.titleContainer}>
      <Title text={title} />
     </Box>
     <Box sx={styles.description}>
      <Typography align={lg ? "center" : "left"} color="text.primary">{NewlineText(description)}</Typography>
     </Box>
     <Box sx={{ textAlign: lg ? "center" : "left" }}>
      {callToAction && (
       <a href={file} style={{ textDecoration: 'none'}}>
        <Button icon="downloadLight" variant="contained">
         {callToAction?.text}
        </Button>
       </a>
      )}
     </Box>
    </Box>
   </Container>
  </Box>
 )}

export { ReportArea }